/* Proiedades del contenedor */
.flex 
    display: flex

.flex-row 
    flex-direction: row
 
.flex-row-reverse 
    flex-direction: row-reverse
 
.flex-column 
    flex-direction: column
 
.flex-column-reverse 
    flex-direction: column-reverse
 
.flex-no-wrap 
    flex-wrap: nowrap
 
.flex-wrap 
    flex-wrap: wrap
 
.flex-wrap-reverse 
    flex-wrap: wrap-reverse
 
.jc-flex-start 
    justify-content: flex-start
 
.jc-flex-end 
    justify-content: flex-end
 
.jc-center 
    justify-content: center
 
.jc-space-between 
    justify-content: space-between
 
.jc-space-around 
    justify-content: space-around
 
.ai-flex-start 
    align-items: flex-start
 
.ai-flex-end 
    align-items: flex-end
 
.ai-center 
    align-items: center
 
.ai-stretch 
    align-items: stretch
 
.ai-baseline 
    align-items: baseline
 
.ac-flex-start 
    align-content: flex-start
 
.ac-flex-end 
    align-content: flex-end
 
.ac-center 
    align-content: center
 
.ac-space-between 
    align-content: space-between
 
.ac-space-around 
    align-content: space-around
 
.ac-stretch 
    align-content: stretch
 
/* propiedades de los elementos hijos*/
.flex-none
    flex: none
 
.flex-auto
    flex: auto
 
.as-flex-start
    align-self: flex-start
 
.as-flex-end
    align-self: flex-end
 
.as-center
    align-self: center
 
.as-stretch
    align-self: stretch
 
.as-baseline
    align-self: baseline
 
.as-auto
    align-self: auto
 

/* Display */ 

.block   
    display: block  
.none   
    display: none  


/* media queries */
@media screen and (min-width: 30em)  
    .sm-flex
        display: flex
        
    .sm-flex-inline 
        display: -ms-inline-flexbox
        
    .sm-flex-row 
        flex-direction: row
        
    .sm-flex-row-reverse 
        flex-direction: row-reverse
        
    .sm-flex-column 
        flex-direction: column
        
    .sm-flex-column-reverse 
        flex-direction: column-reverse
        
    .sm-flex-no-wrap 
        flex-wrap: nowrap
        
    .sm-flex-wrap 
        flex-wrap: wrap
        
    .sm-flex-wrap-reverse
        flex-wrap: wrap-reverse
        
    .sm-jc-flex-start
        justify-content: flex-start
        
    .sm-jc-flex-end
        justify-content: flex-end
        
    .sm-jc-center 
        -webkit-justify-content: center
            -ms-flex-pack: center
        justify-content: center
        
    .sm-jc-space-between
        justify-content: space-between
        
    .sm-jc-space-around
        justify-content: space-around
        
    .sm-ai-flex-start
        align-items: flex-start
        
    .sm-ai-flex-end
        align-items: flex-end
        
    .sm-ai-center
        align-items: center
        
    .sm-ai-stretch
        align-items: stretch
        
    .sm-ai-baseline
        align-items: baseline
        
    .sm-ac-flex-start
        align-content: flex-start
        
    .sm-ac-flex-end 
        align-content: flex-end
        
    .sm-ac-center 
        align-content: center
        
    .sm-ac-space-between 
        align-content: space-between
        
    .sm-ac-space-around 
        align-content: space-around
        
    .sm-ac-stretch 
        align-content: stretch
        
    /* propiedades de los elementos hijos*/
    .sm-flex-none 
        flex: none
        
    .sm-flex-auto
        flex: auto
        
    .sm-as-flex-start 
        align-self: flex-start
        
    .sm-as-flex-end 
        align-self: flex-end
        
    .sm-as-center 
        align-self: center
        
    .sm-as-stretch 
        align-self: stretch
        
    .sm-as-baseline 
        align-self: baseline
        
    .sm-as-auto 
        align-self: auto
    
    /* Display */ 
    .sm-block   
        display: block  
    .sm-none   
        display: none  


        

@media screen and (min-width: 48em)  
    .md-flex
        display: flex
        
    .md-flex-inline 
        display: -ms-inline-flexbox
        
    .md-flex-row 
        flex-direction: row
        
    .md-flex-row-reverse 
        flex-direction: row-reverse
        
    .md-flex-column 
        flex-direction: column
        
    .md-flex-column-reverse 
        flex-direction: column-reverse
        
    .md-flex-no-wrap 
        flex-wrap: nowrap
        
    .md-flex-wrap 
        flex-wrap: wrap
        
    .md-flex-wrap-reverse
        flex-wrap: wrap-reverse
        
    .md-jc-flex-start
        justify-content: flex-start
        
    .md-jc-flex-end
        justify-content: flex-end
        
    .md-jc-center 
        -webkit-justify-content: center
            -ms-flex-pack: center
        justify-content: center
        
    .md-jc-space-between
        justify-content: space-between
        
    .md-jc-space-around
        justify-content: space-around
        
    .md-ai-flex-start
        align-items: flex-start
        
    .md-ai-flex-end
        align-items: flex-end
        
    .md-ai-center
        align-items: center
        
    .md-ai-stretch
        align-items: stretch
        
    .md-ai-baseline
        align-items: baseline
        
    .md-ac-flex-start
        align-content: flex-start
        
    .md-ac-flex-end 
        align-content: flex-end
        
    .md-ac-center 
        align-content: center
        
    .md-ac-space-between 
        align-content: space-between
        
    .md-ac-space-around 
        align-content: space-around
        
    .md-ac-stretch 
        align-content: stretch
        
    /* propiedades de los elementos hijos*/
    .md-flex-none 
        flex: none
        
    .md-flex-auto
        flex: auto
        
    .md-as-flex-start 
        align-self: flex-start
        
    .md-as-flex-end 
        align-self: flex-end
        
    .md-as-center 
        align-self: center
        
    .md-as-stretch 
        align-self: stretch
        
    .md-as-baseline 
        align-self: baseline
        
    .md-as-auto 
        align-self: auto
    
    /* Display */ 
    .md-block   
        display: block  
    .md-none   
        display: none  	 

 

@media screen and (min-width: 64em)  
    .lg-flex
        display: flex
        
    .lg-flex-inline 
        display: -ms-inline-flexbox
        
    .lg-flex-row 
        flex-direction: row
        
    .lg-flex-row-reverse 
        flex-direction: row-reverse
        
    .lg-flex-column 
        flex-direction: column
        
    .lg-flex-column-reverse 
        flex-direction: column-reverse
        
    .lg-flex-no-wrap 
        flex-wrap: nowrap
        
    .lg-flex-wrap 
        flex-wrap: wrap
        
    .lg-flex-wrap-reverse
        flex-wrap: wrap-reverse
        
    .lg-jc-flex-start
        justify-content: flex-start
        
    .lg-jc-flex-end
        justify-content: flex-end
        
    .lg-jc-center 
        -webkit-justify-content: center
            -ms-flex-pack: center
        justify-content: center
        
    .lg-jc-space-between
        justify-content: space-between
        
    .lg-jc-space-around
        justify-content: space-around
        
    .lg-ai-flex-start
        align-items: flex-start
        
    .lg-ai-flex-end
        align-items: flex-end
        
    .lg-ai-center
        align-items: center
        
    .lg-ai-stretch
        align-items: stretch
        
    .lg-ai-baseline
        align-items: baseline
        
    .lg-ac-flex-start
        align-content: flex-start
        
    .lg-ac-flex-end 
        align-content: flex-end
        
    .lg-ac-center 
        align-content: center
        
    .lg-ac-space-between 
        align-content: space-between
        
    .lg-ac-space-around 
        align-content: space-around
        
    .lg-ac-stretch 
        align-content: stretch
        
    /* propiedades de los elementos hijos*/
    .lg-flex-none 
        flex: none
        
    .lg-flex-auto
        flex: auto
        
    .lg-as-flex-start 
        align-self: flex-start
        
    .lg-as-flex-end 
        align-self: flex-end
        
    .lg-as-center 
        align-self: center
        
    .lg-as-stretch 
        align-self: stretch
        
    .lg-as-baseline 
        align-self: baseline
        
    .lg-as-auto 
        align-self: auto
    
    /* Display */ 
    .lg-block   
        display: block  
    .lg-none   
        display: none
