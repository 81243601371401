@use 'sass/palette' as *
@import sass/reset
@import sass/layout
@import sass/grid
@import sass/buttons
@import sass/forms
@import sass/menuTooltip
@import sass/modals

html, body
    min-height: 100vh
    min-width: 100vw
    width: 100%
body
    margin: 0
app-root
    display: block
    min-height: 100vh
    height: 100%
.hide
    display: none