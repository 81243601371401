button.mat-menu-item, .mat-menu-content button
    display: flex
    align-items: center
    color: $dark-color
    font-family: 'Noto Sans'
button.mat-menu-item:hover, .mat-menu-content button:hover
    color: $cta
.mat-menu-item .mat-icon-no-color, .mat-menu-submenu-icon
    color: $dark-color
.mat-menu-item:hover .mat-icon-no-color, .mat-menu-submenu-icon
    color: $cta
.mat-menu-item:hover:not([disabled]), .mat-menu-content button:hover:not([disabled])
    background-color: $medium-color
.mat-elevation-z4
    box-shadow: $task-shadow
