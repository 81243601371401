$light-blue: #8fcef7
$blue: #1f9cee

$logo-color: #2f317c

$color-cyan: #42e8e0
$color-turquoise: #3fb3bd
$color-blue: #106ad2
$color-purple: #a32cdf
$color-purple-dark: #4218b8

/* CTA colors */
$cta: #5755CE 
$cta-hover: #1915BD
/* Gradient colors */
$gradient-start: #05EEFE 
$gradient-end: #1E6AFC  
/* text colors */
$title-color: #2F2E61
$text-color: #53538C
/* other colors */
$white: #FFFFFF
$bg-app: #F8F9FD
$light-color: #F0F0F9
$medium-color: #DADAF0
$dark-color: #A1A1DD
$alert: #f1948a
/*labels */
$yellow: #FBE0A9
$dark-green: #58d68d

/*box shadows */
$form-shadow: 0px 4px 15px -2px rgba(117, 117, 149, 0.25)
$task-shadow: 0px 8px 14px -4px rgba(178, 178, 205, 0.8)