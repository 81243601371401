/* Características de los contenedores */
.container
    display: block
    width: 100%
    margin: auto
    max-width: 1400px

/* Ancho de las columnas en un sistema de 16 columnas */
.col-12
    width: 100%

.col-11
    width: 91.66666666666667%

.col-10
    width: 83.33333333333333%

.col-9
    width: 75%

.col-8
    width: 66.66666666666667%

.col-7
    width: 58.33333333333333%

.col-6
    width: 50%

.col-5
    width: 41.66666666666667%

.col-4
    width: 33.33333333333333%

.col-3
    width: 25%

.col-2
    width: 16.66666666666667%

.col-1
    width: 8.333333333333333%


@media screen and (min-width: 30em) 
    .sm-12
        width: 100%
    
    .sm-11
        width: 91.66666666666667%
    
    .sm-10
        width: 83.33333333333333%
    
    .sm-9
        width: 75%
    
    .sm-8
        width: 66.66666666666667%
    
    .sm-7
        width: 58.33333333333333%
    
    .sm-6
        width: 50%
    
    .sm-5
        width: 41.66666666666667%
    
    .sm-4
        width: 33.33333333333333%
    
    .sm-3
        width: 25%
    
    .sm-2
        width: 16.66666666666667%
    
    .sm-1
        width: 8.333333333333333%
    


@media screen and (min-width: 48em) 
    .md-12
        width: 100%
    
    .md-11
        width: 91.66666666666667%
    
    .md-10
        width: 83.33333333333333%
    
    .md-9
        width: 75%
    
    .md-8
        width: 66.66666666666667%
    
    .md-7
        width: 58.33333333333333%
    
    .md-6
        width: 50%
    
    .md-5
        width: 41.66666666666667%
    
    .md-4
        width: 33.33333333333333%
    
    .md-3
        width: 25%
    
    .md-2
        width: 16.66666666666667%
    
    .md-1
        width: 8.333333333333333%
    


@media screen and (min-width: 64em) 
    .lg-12
        width: 100%
    
    .lg-11
        width: 91.66666666666667%
    
    .lg-10
        width: 83.33333333333333%
    
    .lg-9
        width: 75%
    
    .lg-8
        width: 66.66666666666667%
    
    .lg-7
        width: 58.33333333333333%
    
    .lg-6
        width: 50%
    
    .lg-5
        width: 41.66666666666667%
    
    .lg-4
        width: 33.33333333333333%
    
    .lg-3
        width: 25%
    
    .lg-2
        width: 16.66666666666667%
    
    .lg-1
        width: 8.333333333333333%
    
