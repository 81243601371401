button 
    display: flex
    align-items: center
    font-size: 18px
    gap: 10px
    padding: 10px 
    max-height: 40px
    border: none 
    border-radius: 15px 
    font-size: 16px
    font-weight: 500 
    letter-spacing: 1px
    background: transparent
    cursor: pointer
    font-family: 'Noto Sans', sans-serif
    transition: all 400ms
    outline: none
.btn-primary, .btn-secondary, .btn-menu, .btn-alert
    padding: 10px
.btn-primary
    background-color: $cta
    color: $white
    justify-content: center
    
.btn-primary:hover
    background-color: $cta-hover

.btn-secondary
    color: $cta
    background-color: $medium-color
.btn-secondary:hover
    color: $cta-hover
.btn-menu
    color: $cta
.btn-menu:hover
    color: $cta-hover
    background-color: $medium-color
.btn-add
    color: $cta
.btn-add:hover
    color: $cta-hover
.btn-alert
    background-color: $alert
    color: $white